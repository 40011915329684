export default {
  name: 'profile',
  data () {
    return {
      // radios: null,
      // init_default: null,
      showP: false,
      user_hidden: {
        name: '',
        email: ''
      },
      user_data: {
        name: '',
        email: ''
      },
      user_pass: {
        current_password: '',
        password: '',
        password_confirmation: ''
      },
      show1: false,
      show2: false,
      show3: false,
      loadingUser: false,
      loadingPass: false,
      step: 'tab-1'
      // loadingDflt: false,
      // companies: {},
    }
  },
  methods: {
    getUser: function () {
      axios.get(this.$store.getters.getUserUrl)
        .then((response) => {
          if (response.data) {
            this.user_hidden.name = response.data.name
            this.user_hidden.email = response.data.email
            this.user_data.name = response.data.name
            this.user_data.email = response.data.email
          }
        }).catch((error) => {
          // console.log(error)
        })
    },
    changeProfile(){
      let sendData = {}
      this.$refs.form_profile.validate()
      .then((success) => {
        if (success) {
          if (JSON.stringify(this.user_data) !== JSON.stringify(this.user_hidden)) {
            sendData = this.user_data
            if (this.user_data.name === this.user_hidden.name) {
              sendData = { email: this.user_data.email }
            }
            if (this.user_data.email === this.user_hidden.email) {
              sendData = { name: this.user_data.name }
            }
          }
          if (Object.keys(sendData).length === 0) {
            this.emitNotification(true, 'warning', null, 6000, 'DSB.profile.nothing_to_change')
          } else {
            this.loadingUser = true
            this.$store.dispatch('updateUser', {
              user: sendData
            })
              .then((response) => {
                if (response.data && response.data.success) {
                  this.user_hidden.name = this.user_data.name
                  this.user_hidden.email = this.user_data.email
                  this.emitNotification(true, 'success', null, 4000, 'success',
                    {
                      model: this.$t('DSB.profile.profile'),
                      action: this.$tc('updated',1)
                    }, 1
                  )
                  if (response.data.success.email) {
                    this.$store.dispatch('setVerifiedEmail', false)
                    this.$router.push({ name: 'unverified' })
                  }
                } else {
                  this.emitNotification(true, 'warning', null, 6000, 'base.something_wrong')
                }
                this.loadingUser = false
              }).catch((error) => {
                this.loadingUser = false
              })
          }
        }
      }).catch((error) => {
        // console.log(error)
      })
    },
    changePassword(){
      this.$refs.form_password.validate()
      .then((success) => {
        if (success) {
          if (this.user_pass.current_password === this.user_pass.password) {
            this.emitNotification(true, 'error', null, 4000, 'DSB.profile.current_equal_new')
          } else {
            this.loadingPass = true
            this.$store.dispatch('updateUser', {
              user: this.user_pass
            })
              .then((response) => {
                if (response.data && response.data.success) {
                  this.emitNotification(true, 'success', null, 4000, 'success',
                    {
                      model: this.$t('base.password'),
                      action: this.$tc('updated',2)
                    }, 2
                  )
                } else {
                  this.emitNotification(true, 'warning', null, 6000, 'base.something_wrong')
                }
                this.loadingPass = false
              }).catch((error) => {
                this.loadingPass = false
              })
          }
        }
      }).catch((error) => {
        // console.log(error)
      })
    },
    // Moved to Admin control (requested by client)
    /*getUserDefaultableCompanies(){
      if (!this.$store.getters.isRoot) {
        let obj = this
        axios
          .get(this.$store.getters.listUserDefaultableCompanies)
          .then(response => {
            this.companies = response.data.success
            Object.values(response.data.success).forEach(function (val) {
              if (val.pivot.is_default) {
                obj.radios = val.id
                obj.init_default = val.id
              }
            })
          })
          .catch(error => {
            // console.log(error)
          })
      }
    },
    changeDefaultCompany(id){
      if (!this.radios) {// No company selected
        this.emitNotification(true, 'error', null, 4000, 'company.no_default_selected')
      }
      else if (this.radios == this.init_default) {// Company hasn't changed
        this.emitNotification(true, 'warning', null, 4000, 'DSB.profile.nothing_to_change')
      } else {
        let obj = this
        axios
          .patch(this.$store.getters.setUserDefaultCompany(id))
          .then(response => {
            this.init_default = id
            this.emitNotification(
              true, 'success', null, 4000,
              'company.'+response.data.success,
              '<b>'+obj.$refs[id][0].$refs.label.innerText+'</b>'
            )
          })
          .catch(error => {
            console.log(error)
          })
      }
    },*/
    emitNotification(snackbar, style, mode, timeout, notifText, extra, pos=null) {
      this.$store.dispatch('pushNotification', {
        snackbar:snackbar,
        style:style,
        mode:mode,
        timeout:timeout,
        notifText:notifText,
        notifPos:pos,
        extra: extra || null
      })
    }
  },
  created () {
    this.getUser()
    // this.getUserDefaultableCompanies()
  },
  watch: {
    'user_pass.password':{
      handler(val){
        if(this.showP){
          this.$refs.verify.changePass(val)
        }
      }
    },
    step(val){
      if (val == 'tab-1') {
        setTimeout(() => {
          this.$refs.form_profile.reset();
        }, 10);
      } else if (val == 'tab-2') {
        setTimeout(() => {
          this.$refs.form_password.reset();
        }, 10);
      }
    }
  },
}
