var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('v-tabs',{staticClass:"elevation-1",staticStyle:{"border-radius":"2px 2px 0 0"},attrs:{"centered":"","color":"grey","icons-and-text":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"ml-auto",attrs:{"href":"#tab-1"}},[_vm._v(" "+_vm._s(_vm.$t("DSB.profile.profile"))+" "),_c('v-icon',[_vm._v("fas fa-user")])],1),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" "+_vm._s(_vm.$t("DSB.profile.security"))+" "),_c('v-icon',[_vm._v("fas fa-lock")])],1),_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{class:{ 'px-5': _vm.$vuetify.breakpoint.smAndUp }},[_c('ValidationObserver',{ref:"form_profile",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"profile-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card-text',{staticClass:"text-center pt-2"},[_c('ValidationProvider',{attrs:{"vid":"name","name":_vm.$t('base.name'),"rules":"required|alpha_spaces|min:4|max:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"name","prepend-icon":"fas fa-user","counter":"","maxlength":"14","autocomplete":"off","label":_vm.$t('base.name'),"error-messages":errors},model:{value:(_vm.user_data.name),callback:function ($$v) {_vm.$set(_vm.user_data, "name", $$v)},expression:"user_data.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('base.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"email","prepend-icon":"fas fa-envelope","autocomplete":"off","error-messages":errors,"label":_vm.$t('base.email')},model:{value:(_vm.user_data.email),callback:function ($$v) {_vm.$set(_vm.user_data, "email", $$v)},expression:"user_data.email"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center py-3 pb-4"},[_c('v-btn',{attrs:{"dark":"","loading":_vm.loadingUser,"color":"green lighten-1"},on:{"click":function($event){return _vm.changeProfile()}}},[_vm._v(" "+_vm._s(_vm.$t("base.change"))+" ")])],1)],1)]}}])})],1)],1)],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"text":""}},[_c('v-card-text',{class:{ 'px-5': _vm.$vuetify.breakpoint.smAndUp }},[_c('ValidationObserver',{ref:"form_password",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"password-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card-text',{staticClass:"text-center pt-2"},[_c('ValidationProvider',{attrs:{"vid":"current_password","name":_vm.$t('DSB.profile.current_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"current_password","prepend-icon":"fas fa-lock","label":_vm.$t('DSB.profile.current_password'),"error-messages":errors,"append-icon":_vm.show1 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show1 ? 'text' : 'password'},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.user_pass.current_password),callback:function ($$v) {_vm.$set(_vm.user_pass, "current_password", $$v)},expression:"user_pass.current_password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":_vm.$t('base.pwd', [_vm.$t('DSB.profile.new')]),"rules":{
                        required: true,
                        regex: /^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"name":"password","prepend-icon":"fas fa-lock","counter":"","minlength":"8","maxlength":"16","label":_vm.$t('base.pwd', [_vm.$t('DSB.profile.new')]),"error-messages":errors,"append-icon":_vm.show2 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show2 ? 'text' : 'password'},on:{"focus":function($event){$event.stopPropagation();_vm.showP = true},"blur":function($event){$event.stopPropagation();_vm.showP = false},"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.user_pass.password),callback:function ($$v) {_vm.$set(_vm.user_pass, "password", $$v)},expression:"user_pass.password"}})]}}],null,true)}),_c('verifypassword',{directives:[{name:"show",rawName:"v-show",value:(_vm.showP),expression:"showP"}],ref:"verify",staticClass:"text-left"}),_c('ValidationProvider',{attrs:{"vid":"password_confirmation","name":_vm.$t('base.pwd', [_vm.$t('DSB.profile.confirm_new')]),"rules":{
                        required: true,
                        passConfirm: _vm.user_pass.password
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password_confirmation","prepend-icon":"fas fa-lock","counter":"","minlength":"8","maxlength":"16","label":_vm.$t('base.pwd', [_vm.$t('DSB.profile.confirm_new')]),"error-messages":errors,"append-icon":_vm.show3 ? 'fas fa-eye' : 'fas fa-eye-slash',"type":_vm.show3 ? 'text' : 'password'},on:{"click:append":function($event){_vm.show3 = !_vm.show3}},model:{value:(_vm.user_pass.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user_pass, "password_confirmation", $$v)},expression:"user_pass.password_confirmation"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-center py-3 pb-4"},[_c('v-btn',{attrs:{"dark":"","loading":_vm.loadingPass,"color":"green lighten-1"},on:{"click":_vm.changePassword}},[_vm._v(" "+_vm._s(_vm.$t("base.change"))+" ")])],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }